import makeRequest from '../makeRequest';
import {GetFaqResponse, GetFaqsResponse} from '../../interfaces/api/FaqResponse';
import {IFaqView} from '../../interfaces/entities/IFaq';

export class FaqService {
	async fetchFaqs(query: string) {
		const response = await makeRequest<GetFaqsResponse>({
			url: `faq_questions?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.faq_questions,
			meta: response.data.meta,
		};
	}

	async fetchFaq(id: string) {
		const response = await makeRequest<GetFaqResponse>({
			url: `faq_questions/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.faq_question,
		};
	}

	async createFaq(faq_question: IFaqView) {
		const response = await makeRequest<GetFaqResponse>({
			url: 'faq_questions',
			method: 'post',
			auth: true,
			data: faq_question,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.faq_question,
		};
	}

	async updateFaq(faq_question: IFaqView) {
		const response = await makeRequest<GetFaqResponse>({
			url: `faq_questions/${faq_question.id}`,
			method: 'put',
			auth: true,
			data: faq_question,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.faq_question,
		};
	}

	deleteFaq(id: number) {
		return makeRequest<void>({
			url: `faq_questions/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new FaqService();
