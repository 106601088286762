import {ListStore} from '../../general/List/lIstStore';
import { IFaqView} from '../../../interfaces/entities/IFaq';
import FaqService from '../../../API/rest/FaqService';


class FaqStore extends ListStore<IFaqView, IFaqView> {
	constructor() {
		super({
			APIFetchItems: FaqService.fetchFaqs,
			APIFetchItem: FaqService.fetchFaq,
			APICreateItem: FaqService.createFaq,
			APIUpdateItem: FaqService.updateFaq,
			APIRemoveItem: FaqService.deleteFaq,
		});
	}
}

export default FaqStore;
