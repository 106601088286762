import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';

import { FieldsType } from 'components/Logic/FieldByType/types';

const FaqUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.faqs.element;

	const fetchData = useCallback(async () => {
		if (id) {
			await store.faqs.fetchItem(id);
		}
	}, [id]);

	const eventsFields: FieldsType[] = [
		{
			type: 'textfield',
			label: 'Вопрос',
			name: 'name',
			value: item.name,
		},
		{
			type: 'textarea',
			label: 'Ответ',
			name: 'answer',
			value: item.answer,
		},
		{
			type: 'switch',
			label: 'Активно',
			name: 'is_active',
			value: item.is_active,
		}
	];

	return (
		<UpdatePage
			title={'FAQ / Изменение'}
			fields={eventsFields}
			store={store.faqs}
			fetchData={fetchData}
		/>
	);
});
export default FaqUpdate;
