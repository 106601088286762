import { staticLinks } from 'config/routingLinks';

export const sidebarConfig = [
	{
		to: staticLinks.users,
		name: 'Пользователи',
	},
	{
		title: 'Требует одобрения',
		navigation: [
			{
				to: staticLinks.resumes,
				name: 'Анкеты',
			},
			{
				to: staticLinks.employers,
				name: 'Компании',
			},
			{
				to: staticLinks.vacancies,
				name: 'Вакансии',
			},
			{
				to: staticLinks.courses,
				name: 'Курсы',
			},
		],
	},
	{
		title: 'Элементы системы',
		navigation: [
			{
				to: staticLinks.specialities,
				name: 'Специальности',
			},
			{
				to: staticLinks.personalQualities,
				name: 'Личностные качества',
			},
			{
				to: staticLinks.spheres,
				name: 'Сферы',
			},
			{
				to: staticLinks.careerGuidance,
				name: 'Профориентация',
			},
			{
				to: staticLinks.professionalQualities,
				name: 'Проф. качества',
			},
			{
				to: staticLinks.softwareProducts,
				name: 'Прогр. продукты',
			},
			{
				to: staticLinks.championships,
				name: 'Чемпионаты',
			},
			{
				to: staticLinks.practiceStudents,
				name: 'График практик студентов',
			},
			{
				to: staticLinks.careerPractices,
				name: 'Практики профориентации',
			},
		],
	},
	{
		title: 'Выводимый контент',
		navigation: [
			{
				to: staticLinks.formBuilder,
				name: 'Формы запросов',
			},
			{
				to: staticLinks.calculatorBuilder,
				name: 'Конструктор калькулятора',
			},
			{
				to: staticLinks.statusCards,
				name: 'Карточки услуг',
			},
			{
				to: staticLinks.eventCards,
				name: 'Важные мероприятия',
			},
			{
				to: staticLinks.mailDistributions,
				name: 'Рассылка писем',
			},
			{
				to: staticLinks.articles,
				name: 'Статьи',
			},
			{
				to: staticLinks.supportMeasures,
				name: 'Меры поддержки',
			},
			{
				to: staticLinks.calendarEvents,
				name: 'Календарь',
			},
			// {
			// 	to: staticLinks.pavilions,
			// 	name: 'Павильоны',
			// },
			{
				to: staticLinks.pavilionsOstrova,
				name: 'Образовательные проекты',
			},

			{
				to: staticLinks.faqs,
				name: 'FAQ',
			},
		],
	},
	{
		title: 'Заявки',
		navigation: [
			{
				to: staticLinks.additionalApplications,
				name: 'Помощь',
			},
			{
				to: staticLinks.reports,
				name: 'Жалобы',
			},
			{
				to: staticLinks.answers,
				name: 'Ответы запросов',
			},
			{
				to: staticLinks.careerTools,
				name: 'Заявки запросов',
			},
		],
	},
	{
		title: 'Школьники',
		navigation: [
			{
				to: staticLinks.schoolUsers,
				name: 'Пользователи',
			},
			{
				to: staticLinks.schoolResumes,
				name: 'Анкеты',
			},
			{
				to: staticLinks.activities,
				name: 'Активности',
			},
		],
	},
];
