import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';


import styles from './Faqs.module.scss';
import {IFaqView} from '../../interfaces/entities/IFaq';

const Faq = observer(({ item }: {item: IFaqView}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/update${location.search}`);
	};

	const approveClasses = item.is_active
		? styles.approved
		: styles.notApproved;

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.name}</span>
			</div>
			<div className={styles.cell}>
				<span
					className={clsx(approveClasses, 'wrap-text limit-line-text')}>{item.is_active ? 'Активно' : 'Неактивно'}</span>
			</div>
			<div className={styles.cell} onClick={onLinkToUpdate}>
				<div className='edit-icon-block'>
					<span className='update-text'>Изменить</span>
				</div>
			</div>
		</div>
	);
});

export default Faq;
