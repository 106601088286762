import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';

import styles from './Faqs.module.scss';
import Faq from './Faq';
import FaqsTableHeader from './FaqsTableHeader';

const Faqs: FC = observer(() => {
	const store = useStore();

	const exportConfig = {
		withSearchParams: true,
		list: [
			{
				path: 'faqs',
				label: 'FAQ',
			},
		],
	};

	return (
		<ListPage
			store={store.faqs}
			title='Анкеты'
			tableHeader={FaqsTableHeader}
			tableClassName={styles.table}
			element={Faq}
			hasCreate={true}
		/>
	);
});

export default Faqs;
