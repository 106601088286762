import { makeAutoObservable } from 'mobx';

import Auth from 'store/auth';
import PracticeStudentsStore from './entities/practiceStudents/practiceStudentsStore';
import UserStore from './entities/users/userStore';
import SpecialityStore from './entities/specialities/specialityStore';
import ProfessionalQualityStore from './entities/professionalQualities/professionalQualityStore';
import SoftwareProductStore from './entities/softwareProducts/softwareProductsStore';
import ChampionshipStore from './entities/championships/championshipStore';
import PersonalQualityStore from './entities/personalQualities/personalQualityStore';
import SphereStore from './entities/spheres/sphereStore';
import AdditionalApplicationStore from './entities/additionalApplications/additionalApplicationStore';
import ResumeStore from './entities/resumes/resumeStore';
import VacancyStore from './entities/vacancies/vacancyStore';
import EmployerStore from './entities/employers/employerStore';
import CourseStore from './entities/courses/courseStore';
import ReportStore from './entities/reports/reportStore';
import StatusCardStore from './entities/statusCards/statusCardStore';
import QuestionStore from './entities/questions/questionStore';
import AnswerStore from './entities/answers/answerStore';
import EventStore from './entities/events/eventStore';
import MailDistributionStore from './entities/mailDistributions/mailDistributions';
import ActivitiesStore from './entities/activities/activitiesStore';
import SchoolResumeStore from './entities/schoolResumes/schoolResumeStore';
import SchoolUserStore from './entities/schoolUsers/userStore';
import ProfessionStore from './entities/professions/professionStore';
import CareerPracticeStore from './entities/careerPractices/careerPracticeStore';
import CalendarEventStore from './entities/calendarEvents/calendarEvent';
import SupportMeasureStore from './entities/supportMeasures/supportMeasureStore';
import CareerToolsStore from './entities/careerTools/careerToolsStore';
import ArticleStore from './entities/articles/articleStore';
import EditorStore from './entities/editor/editorStore';
import PavilionStore from './entities/pavilions/pavilionStore';
import CareerGuidanceStore from './entities/careerGuidance/careerGuidance';

import { IStore } from 'interfaces/stores/IStore';
import { IAuthStore } from 'interfaces/stores/IAuthStore';
import { IUserStore } from './entities/users/types';
import { ISpecialityStore } from './entities/specialities/types';
import { IProfessionalQualityStore } from './entities/professionalQualities/types';
import { ISoftwareProductStore } from './entities/softwareProducts/types';
import { IChampionshipStore } from './entities/championships/types';
import { IPersonalQualityStore } from './entities/personalQualities/types';
import { ISphereStore } from './entities/spheres/types';
import { IAdditionalApplicationStore } from './entities/additionalApplications/types';
import { IResumeStore } from './entities/resumes/types';
import { IVacancyStore } from './entities/vacancies/types';
import { IEmployerStore } from './entities/employers/types';
import { ICourseStore } from './entities/courses/types';
import { IReportStore } from './entities/reports/types';
import { IStatusCardStore } from './entities/statusCards/types';
import { IAnswerStore } from './entities/answers/types';
import { IEventStore } from './entities/events/types';
import { IMailDistributionStore } from './entities/mailDistributions/types';
import { IPracticeStudentStore } from './entities/practiceStudents/types';
import { IActivitiesStore } from './entities/activities/types';
import { ICalendarEventStore } from './entities/calendarEvents/types';
import { ISchoolResumeStore } from './entities/schoolResumes/types';
import { ISchoolUserStore } from './entities/schoolUsers/types';
import { IProfessionStore } from './entities/professions/types';
import { ICareerPracticeStore } from './entities/careerPractices/types';
import { ISupportMeasureStore } from './entities/supportMeasures/types';
import { ICareerToolsStore } from './entities/careerTools/types';
import { IArticleStore } from './entities/articles/types';
import { IEditorStore } from './entities/editor/types';
import { IAppSettingsStore } from './entities/appSettings/types';
import { IPavilionStore } from './entities/pavilions/types';
import { AppSettingsStore } from './entities/appSettings/appSettingsStore';
import { ICareerGuidanceStore } from './entities/careerGuidance/types';
import {IPavilionOstrovaStore} from './entities/pavilionsOstrova/types';
import PavilionOstrovaStore from './entities/pavilionsOstrova/pavilionStore';
import EventOstrovaStore from './entities/eventsOstrova/eventStore';
import FaqStore from './entities/faq/faqStore';
import {IFaqStore} from './entities/faq/types';

class Store implements IStore {
	auth: IAuthStore;

	users: IUserStore;
	specialities: ISpecialityStore;
	professionalQualities: IProfessionalQualityStore;
	championships: IChampionshipStore;
	softwareProducts: ISoftwareProductStore;
	personalQualities: IPersonalQualityStore;
	spheres: ISphereStore;
	additionalApplications: IAdditionalApplicationStore;
	resumes: IResumeStore;
	schoolResumes: ISchoolResumeStore;
	vacancies: IVacancyStore;
	employers: IEmployerStore;
	courses: ICourseStore;
	reports: IReportStore;
	questions: IReportStore;
	statusCards: IStatusCardStore;
	answers: IAnswerStore;
	events: IEventStore;
	articles: IArticleStore;
	supportMeasure: ISupportMeasureStore;
	practiceStudents: IPracticeStudentStore;
	mailDistributions: IMailDistributionStore;
	activities: IActivitiesStore;
	calendarEvents: ICalendarEventStore;
	schoolUsers: ISchoolUserStore;
	professions: IProfessionStore;
	careerPractices: ICareerPracticeStore;
	careerTools: ICareerToolsStore;
	editor: IEditorStore;
	appSettings: IAppSettingsStore;
	pavilions: IPavilionStore;
	pavilionsOstrova: IPavilionOstrovaStore;
	eventsOstrova: IEventStore;
	careerGuidance: ICareerGuidanceStore;
	faqs: IFaqStore;

	constructor() {
		this.auth = new Auth();

		this.users = new UserStore();
		this.specialities = new SpecialityStore();
		this.professionalQualities = new ProfessionalQualityStore();
		this.championships = new ChampionshipStore();
		this.softwareProducts = new SoftwareProductStore();
		this.personalQualities = new PersonalQualityStore();
		this.spheres = new SphereStore();
		this.additionalApplications = new AdditionalApplicationStore();
		this.resumes = new ResumeStore();
		this.schoolResumes = new SchoolResumeStore();
		this.vacancies = new VacancyStore();
		this.employers = new EmployerStore();
		this.courses = new CourseStore();
		this.reports = new ReportStore();
		this.questions = new QuestionStore();
		this.statusCards = new StatusCardStore();
		this.answers = new AnswerStore();
		this.events = new EventStore();
		this.eventsOstrova =new EventOstrovaStore();
		this.articles = new ArticleStore();
		this.practiceStudents = new PracticeStudentsStore();
		this.mailDistributions = new MailDistributionStore();
		this.activities = new ActivitiesStore();
		this.calendarEvents = new CalendarEventStore();
		this.schoolUsers = new SchoolUserStore();
		this.professions = new ProfessionStore();
		this.careerPractices = new CareerPracticeStore();
		this.supportMeasure = new SupportMeasureStore();
		this.careerTools = new CareerToolsStore();
		this.editor = new EditorStore();
		this.appSettings = new AppSettingsStore();
		this.pavilions = new PavilionStore();
		this.pavilionsOstrova = new PavilionOstrovaStore();
		this.careerGuidance = new CareerGuidanceStore();
		this.faqs = new FaqStore();

		makeAutoObservable(this);
	}
}

export default Store;
