type TStaticLinks = {
	[key: string]: string;
};

export const staticLinks: TStaticLinks = {
	auth: '/authorization',
	main: '',
	documents: 'documents/:id/update',
	careerPracticesQrs: 'career_practices/:id/qrs',
	unpaidEmailSubscription: '/unpaid_email_subscription',
	careerGuidance: 'career-guidance',
	...CRLink('users', 'users'),
	...CRLink('schoolUsers', 'school_users'),
	...CRLink('answers', 'answers'),
	...CRLink('additionalApplications', 'additional_applications'),
	...CRUDLink('specialities', 'specialities'),
	...CRUDLink('professionalQualities', 'professional_qualities'),
	...CRUDLink('personalQualities', 'personal_qualities'),
	...CRUDLink('championships', 'championships'),
	...CRUDLink('softwareProducts', 'software_products'),
	...CRUDLink('personalQualities', 'personal_qualities'),
	...CRUDLink('spheres', 'spheres'),
	...CRUDLink('professions', 'professions'),
	...CRUDLink('formBuilder', 'form_builder'),
	...CRUDLink('calculatorBuilder', 'calculator_builder'),
	...CRUDLink('resumes', 'resumes'),
	...CRUDLink('schoolResumes', 'school_resumes'),
	...CRUDLink('vacancies', 'vacancies'),
	...CRUDLink('employers', 'employers'),
	...CRUDLink('courses', 'courses'),
	...CRUDLink('reports', 'reports'),
	...CRUDLink('statusCards', 'status_cards'),
	...CRUDLink('eventCards', 'event_cards'),
	...CRUDLink('eventOstrova', 'event_ostrova'),
	...CRUDLink('articles', 'articles'),
	...CRUDLink('activities', 'activities'),
	...CRUDLink('practiceStudents', 'practice_students'),
	...CRUDLink('mailDistributions', 'mail_distributions'),
	...CRUDLink('calendarEvents', 'calendar_events'),
	...CRUDLink('careerPractices', 'career_practices'),
	...CRUDLink('supportMeasures', 'support_measures'),
	...CRUDLink('careerTools', 'career_tools'),
	...CRUDLink('professions', 'spheres/:sphere_id/professions'),
	...CRUDLink('pavilions', 'pavilions'),
	...CRUDLink('pavilionsOstrova', 'obrazovatelnye_proekty'),
	...CRUDLink('faqs', 'faqs'),
};

export const dynamicLinks = (id: number): TStaticLinks => ({
	...CRUDLink('professions', `spheres/${id}/professions`),
	...CRLink('users', `users/${id}/read?role=applicant`),
});

function CRUDLink(name: string, url: string) {
	const newUrl = `/${url}`;

	return {
		[name]: newUrl, // read all
		[`${name}Create`]: `${newUrl}/create`, // create
		[`${name}Update`]: `${newUrl}/:id/update`, // update
	};
}

function CRLink(name: string, url: string) {
	const newUrl = `/${url}`;

	return {
		[name]: newUrl, // read all
		[`${name}Read`]: `${newUrl}/:id/read`, // read one
		[`${name}Update`]: `${newUrl}/update`, // read one
	};
}
