import {
	activeOptions,
	TableHeader,
	THeaderField,
} from 'components/Layouts';

import styles from './Faqs.module.scss';

const FaqsTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Название', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Статус', name: 'is_active', type: 'select', options: activeOptions },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default FaqsTableHeader;
