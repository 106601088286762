import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { CreatePage } from 'components/Layouts';

const FaqCreate: FC = observer(() => {
	const store = useStore();

	const faqsFields: FieldsType[] = [
		{
			type: 'textfield',
			label: 'Вопрос',
			name: 'name',
		},
		{
			type: 'textarea',
			label: 'Ответ',
			name: 'answer',
		},
		{
			type: 'switch',
			label: 'Активно',
			name: 'is_active',
			value: true,
		}

	];

	return (
		<CreatePage
			title={'FAQ / Создание'}
			fields={faqsFields}
			store={store.faqs}
		/>
	);
});

export default FaqCreate;
